// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-btn button {
    background-color: #1D3557; /* Button color */
    font-family: 'Poppins';
    color: white;   
    border-radius: 4px;           /* Text color */
    padding: 6px 16px;
    border-style: none;
    font-size: 11px;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/Components/PaymentComponent/payment.css"],"names":[],"mappings":"AAGA;IACI,yBAAyB,EAAE,iBAAiB;IAC5C,sBAAsB;IACtB,YAAY;IACZ,kBAAkB,YAAY,eAAe;IAC7C,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');\n\n\n.payment-btn button {\n    background-color: #1D3557; /* Button color */\n    font-family: 'Poppins';\n    color: white;   \n    border-radius: 4px;           /* Text color */\n    padding: 6px 16px;\n    border-style: none;\n    font-size: 11px;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
