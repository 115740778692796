// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-light{
 
    background-color: #E63946 !important;
    border-radius: 6px !important;
    padding: 3px 21px !important;
    color : #fff !important;
    border-style: none !important;
    margin-left: 15px;    
}
 

.business-offer{
 
    font-family: 'Poppins';
}
 
.offer-title{
   
    font-weight: 400 !important;
    font-size: 18px;
 
}
 
.offer-description{
    color: #757575 !important;
    font-weight: 100;
}
.offer-code{
    font-family: 'Poppins';
    color: #212121

}

.no-offer{

    font-family: 'Poppins';
}`, "",{"version":3,"sources":["webpack://./src/Components/BusinessOffer/Offers.css"],"names":[],"mappings":"AAIA;;IAEI,oCAAoC;IACpC,6BAA6B;IAC7B,4BAA4B;IAC5B,uBAAuB;IACvB,6BAA6B;IAC7B,iBAAiB;AACrB;;;AAGA;;IAEI,sBAAsB;AAC1B;;AAEA;;IAEI,2BAA2B;IAC3B,eAAe;;AAEnB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;AACpB;AACA;IACI,sBAAsB;IACtB;;AAEJ;;AAEA;;IAEI,sBAAsB;AAC1B","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');\n \n \n \n.btn-light{\n \n    background-color: #E63946 !important;\n    border-radius: 6px !important;\n    padding: 3px 21px !important;\n    color : #fff !important;\n    border-style: none !important;\n    margin-left: 15px;    \n}\n \n\n.business-offer{\n \n    font-family: 'Poppins';\n}\n \n.offer-title{\n   \n    font-weight: 400 !important;\n    font-size: 18px;\n \n}\n \n.offer-description{\n    color: #757575 !important;\n    font-weight: 100;\n}\n.offer-code{\n    font-family: 'Poppins';\n    color: #212121\n\n}\n\n.no-offer{\n\n    font-family: 'Poppins';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
