import React, { useState, useEffect, useRef } from 'react';
import { useServiceContext } from '../contextapi/Servicecontext';
import './business.css';
import ContactModal from './ContactModal';
import Banner from './Banner';
import apiservice from '../../apiServices';


const BusinessListing = () => {

    const [loading, setLoading] = useState(false); // Loading state
    const { selectedlocation, selectedDistance, start_index, setStartIndex, businesses, setBusinesses, translations, searchbusiness, setBusinessid, setBusinessname,setBusinessnumber, selectedServices, nolocation,  setSearchbusiness
    } = useServiceContext(); // Selected location and distance
    const scrollableContainerRef = useRef(null); // Reference to the scrollable container
    const [imagepath, setImagepath] = useState(''); // Path for business images
    const [defaultpath, setDefaultpath] = useState(''); // Default path for business images
    const [isLoginModalVisibleList, setIsLoginModalVisibleList] = useState([]);
    const [currentOpenModalIndex, setCurrentOpenModalIndex] = useState(null);
    const categoryScrollRef = useRef(null);
    const [serviceTypes, setServiceTypes] = useState([]);
    const [serviceimagepath, setServiceimagepath] = useState('');
    const [serviceIndex, setServiceIndex] = useState(null);
    const [selectedServiceId, setSelectedServiceId] = useState(null);
    const [Status, setStatus] = useState()
    const [loadingMore, setLoadingMore] = useState(false);
    const [usercategory, setUserCategory] = useState('');

    const getCategoryItemWidth = () => {
        const firstCategoryItem = document.querySelector('.category-item');
        return firstCategoryItem ? firstCategoryItem.clientWidth : 100; // Fallback to 100px if undefined
    };
    const handleScrollLeft = () => {
        const container = categoryScrollRef.current;
        if (container) {
            const itemWidth = getCategoryItemWidth();
            container.scrollLeft -= itemWidth * 11; // Scroll four elements to the left
        }
    };

    const handleScrollRight = () => {
        const container = categoryScrollRef.current;
        if (container) {
            const itemWidth = getCategoryItemWidth();
            container.scrollLeft += itemWidth * 11; // Scroll four elements to the right
        }
    };
    const openModalForBusiness = (index, business_name, Mobile) => {
        setIsLoginModalVisibleList((prevList) => {
            const newList = [...prevList];
            newList[index] = true;
            return newList;
        });
        setBusinessid(index);
        setBusinessname(business_name);
        setBusinessnumber(Mobile);

        setCurrentOpenModalIndex(index);

    };

    const closeModalForBusiness = (index) => {
        setIsLoginModalVisibleList((prevList) => {
            const newList = [...prevList];
            newList[index] = false;
            return newList;
        });
        setCurrentOpenModalIndex(null);

    };

    const handleBookAppointment = (url, ST_name) => {

        let baseUrl = window.location.href.split('/l')[0]; 
        const category = ST_name?.replace(/\s+/g, '')
        const categoryName = category.toLowerCase()

        if (!baseUrl.endsWith('/')) {
            baseUrl += '/';
        }
        const newUrl = baseUrl + 'l/' + url; // Combine base URL with '/l/' and the function argument
        window.location.href = newUrl;
    }

    const fetchDataBasedOnDistance = async (selectedDistance, start_index, Id) => {

        const api = process.env.REACT_APP_API_URL + 'getBusinessByFilter';
        try {
            if (start_index < 1) {
                setLoading(true); // Set loading true only for initial data fetching
                setStatus(true)
            } else {
                setLoadingMore(true); // Set loading more true when loading more data
            }
            const requestData = {
                lat: selectedlocation.latitude,
                long: selectedlocation.longitude,
                radius: selectedDistance,
                businessname: searchbusiness,
                stid: serviceIndex,
                device_id: '',
                from: '0',
                start_index: start_index,
            };
            const data = await apiservice(api, 'POST', requestData);
            // if (data.status === 'false') {
            //     setLoading(false);
            // }
            setStatus(data.status)
            if (data && data.status === 'true' && data.finalres && data.finalres.length > 0) {
                if (start_index == 0) {
                    setBusinesses(data.finalres)
                }
                else {
                    setBusinesses((prevBusinesses) => [...prevBusinesses, ...data.finalres]);
                }
                setImagepath(data.imagepath);
                setDefaultpath(data.defaultBusinessPath);
                setStartIndex(data.last_index);
            }
            setLoading(false);
            setLoadingMore(false);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false)
        }
    };

    const handleScroll = () => {
        setLoading(false)
        const scrollableContainer = scrollableContainerRef.current; 
        if (scrollableContainer) {

            const scrollTop = scrollableContainer.scrollTop;
            const scrollHeight = scrollableContainer.scrollHeight;
            const clientHeight = scrollableContainer.clientHeight;

            // Check if the user has scrolled to the bottom and fetch more data
            if (scrollTop + clientHeight >= scrollHeight - 20 && !loadingMore && businesses.length > 0) {
                setLoadingMore(true);
                fetchDataBasedOnDistance(selectedDistance, start_index).then(() => {
                setLoadingMore(false)
                });
            }
        }
    };

    useEffect(() => {

        const scrollableContainer = scrollableContainerRef.current;
        if (scrollableContainer) {
            scrollableContainer.addEventListener('scroll', handleScroll);
        }
        return () => {

            if (scrollableContainer) {
                scrollableContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [loadingMore, businesses, loading]);

    useEffect(() => {

        setStartIndex(0);
        setBusinesses([]);
        const fetchData = async () => {
            try {

                setTimeout(async () => {
                    await fetchDataBasedOnDistance(selectedDistance, 0);
                }, 100);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
            }
        };

        if (Object.keys(selectedlocation).length > 0) {
            fetchData();
        }
    }, [selectedDistance, selectedlocation, searchbusiness, serviceIndex]);


    useEffect(() => {

        sessionStorage.setItem('selectedServices', JSON.stringify([]));
        sessionStorage.setItem('serviceIcons', JSON.stringify([]));
    }, [selectedServices])

    useEffect(() => {

        async function fetchServiceTypes() {
            try {
                const api = process.env.REACT_APP_API_URL + 'getServiceType';
                const data = await apiservice(api, 'GET');
                const sortedServiceTypes = data.servicedata.sort((a, b) => {

                    if (a["Sort Order"] === b["Sort Order"]) {
                        return 0;
                    } else if (a["Sort Order"] === "" || a["Sort Order"] === null) {
                        return 1;
                    } else if (b["Sort Order"] === "" || b["Sort Order"] === null) {
                        return -1;
                    } else {
                        return a["Sort Order"] - b["Sort Order"];
                    }
                });
                setServiceTypes(sortedServiceTypes);
                setServiceimagepath(data.ImagePath)

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchServiceTypes();
    }, []);


    const handleserviceId = (service) => {
        const updatedServiceTypes = serviceTypes.map(item => {
            if (item.Id == service.Id) {
                return { ...item, 'Sort Order': 1 };
            } else {
                return { ...item };
            }
        });
        setServiceTypes(updatedServiceTypes);
        setServiceIndex(service.Id);
        setSelectedServiceId(service.Id);
    
        // Construct the new URL
        const url = window.location.href; // Get the current URL
        const pattern = /\/l\/[^/]+\/([^/]+)$/;
        const isMatchingPattern = url.match(pattern);
    
        if (isMatchingPattern) {
            const baseUrl = window.location.href;
            const urlParts = baseUrl.split('/');
            const serviceName = service.Name.toLowerCase().replace(/\s+/g, '-')
            ; // Remove spaces and convert to lowercase
            urlParts[urlParts.length - 1] = serviceName;
            const newUrl = urlParts.join('/');
    
            // Redirect to the new URL with a page refresh
            window.location.href = newUrl;
        }
    };
      

    const handleserviceIdAll = () => {
        setServiceIndex(null)
        setSelectedServiceId(null);
        setSearchbusiness(searchbusiness)
    
    }

    useEffect(() => {


        const url = window.location.href; // Get the current URL
        const pattern = /\/l\/[^/]+\/([^/]+)$/;
        const isMatchingPattern = url.match(pattern);

        if (isMatchingPattern) {
            const usercategory = url.split('/').pop().toLowerCase().replace(/_/g, ' ');
            setUserCategory(usercategory);
            const service = serviceTypes.find(s => s.Name.toLowerCase().replace(/\s+/g, '-') == usercategory);

            if (service) {
                setServiceIndex(service.Id);
                setSelectedServiceId(service.Id);

                const reorderedServiceTypes = [
                    service,
                    ...serviceTypes.filter(s => s.Id !== service.Id)
                ];
                setServiceTypes(reorderedServiceTypes);
            } 
        }
    }, [loading]);

    return (
        <> 
            <div className='col-md-12'>
                <div className='sticky-top bg-white z-0'>
                    <div> {serviceTypes.length > 0 && <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                            className="d-none d-md-block"
                            style={{
                                marginRight: '10px',
                                cursor: 'pointer'
                            }}
                            onClick={handleScrollLeft}
                        >
                            <span style={{ fontSize: '18px' }}><i className="fa-solid fa-chevron-left"></i>{/* Left arrow icon */} </span>
                        </div>
                        <div
                            ref={categoryScrollRef}
                            className="category-items"
                            style={{
                                display: 'flex',
                                overflowX: 'auto',
                                whiteSpace: 'nowrap',
                                margin: '5px 0px',
                            }}


                        >
                            <div className='d-flex ' style={{ flexDirection: 'column', alignItems: 'center' }}>
                                <div className={`category-item ${selectedServiceId === null ? 'selected' : ''}`} style={{ display: 'flex', justifyContent: 'center', fontSize: '18px' }} onClick={handleserviceIdAll}><i class="fa-solid fa-border-all"></i>
                                </div>
                                <div className='service-name'>All </div>
                            </div>
                            {serviceTypes.map((service) => (

                                <div className='cat-width'
                                    key={service.Id}
                                    onClick={() => { handleserviceId(service) }}
                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}

                                >   <div className={`category-item ${selectedServiceId == service.Id ? 'selected' : ''}`}
                                >
                                        <img
                                            src={serviceimagepath + service.Icon}
                                            style={{ width: '100%', filter: selectedServiceId == service.Id ? 'invert(100%)' : 'none' }}

                                        />
                                    </div>

                                    <div className='service-name'>{service.Name}</div>
                                </div>
                            ))}
                        </div>

                        <div className="d-none d-md-block"
                            style={{
                                marginLeft: '10px',
                                cursor: 'pointer'
                            }}
                            onClick={handleScrollRight}
                        >
                            <span style={{ fontSize: '18px' }}> <i className="fa-solid fa-chevron-right"></i>{/* Left arrow icon */} </span>

                        </div>
                    </div>}
                    </div>
                </div>
            </div>
            {loading && <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center', height: '56px', width: '56px', animation: 'rotate 2s linear infinite', marginTop: '120px'
            }} className='loaders loader-overlay'>

            </div>}

            {loading && <div className='loaders-overlay' style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '12px',
            }}> <img src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/whizzq-icon/whizzqlogo_dec23.png" width='120px'></img></div>}




            {(selectedlocation != null && selectedlocation != undefined) ? (
                <div className='col-md-12'>
                    <div className='row mt-2 scrollable-container overflow-auto' ref={scrollableContainerRef}   >

                        {businesses.length > 0 ? (businesses.map(({ id, defaultBusinessPic, business_profile, business_name, serviceType_profileimage, address, distance, Mobile, accepting_appointments, redirect_url, business_id, lat, long, ST_name, count_offer
                        }) => (
                            <div key={`${id}-${business_name}`} className='col-md-6'>
                                <div className='business-item'>
                                    <div className='b-img'>
                                        <img src={(business_profile ? (imagepath + business_profile) : (defaultpath + serviceType_profileimage)) || 'fallback/default_image.png'}
                                            className='w-100' alt="" />
                                    </div>
                                    <div className='b-content'>

                                        <div className='b-name'>{business_name}</div>
                                        <div className='b-address'>{address}</div>
                                        <div className='d-flex justify-content-between'><div className='b-distance'><i className="fa-solid fa-location-dot"></i> {distance}  <span> {currentOpenModalIndex === id && <ContactModal onClose={() => closeModalForBusiness(id)} />}</span> </div>
                                            <div className='pointers'>
                                                {(accepting_appointments === 'false' && (business_id === null || business_id == 0)) || ((accepting_appointments === 'true') && (business_id == 0)) ? (
                                                    <div className='coming'>
                                                        <div onClick={() => openModalForBusiness(id, business_name, Mobile)}>
                                                            <span> <img src='https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/images/contact-btn+2.png' alt='whizzqicon' width='130px' />    </span>


                                                        </div>

                                                    </div>
                                                ) : accepting_appointments === 'false' && business_id !== null && business_id > 0 ? (
                                                    <div className='coming' >
                                                        <span> <img src='https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/images/soon+1.png' alt='whizzqicon' width='130px' />    </span>

                                                    </div>
                                                ) : (
                                                    <div onClick={() => handleBookAppointment(redirect_url, ST_name, lat, long)} >
                                                        <span> <img src='https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/images/Button2+1.png' alt='whizzqicon' width='130px' />    </span>
                                                    </div>
                                                )}
                                            </div></div>
                                        <div> </div>


                                    </div>
                                </div>
                            </div>
                        ))
                        ) :
                            (
                                Status == 'true' && (
                                    <div className='no-business-message'>
                                        <img src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/images/nobusiness.png" alt='no-business' />
                                        <span>{translations.business}</span>
                                    </div>
                                )
                            )
                        }

                    </div>
                </div>
            )

                : (
                    <div className='no-business-message'>
                        {translations.location}
                    </div>
                )

            }

            {loadingMore && <div style={{
                textAlign: 'center', height: '66px', width: '66px', animation: 'rotate 2s linear infinite', // Adjust duration and timing function as needed
            }} className='loaders'></div>}

        </>
    );
};

export default BusinessListing;
