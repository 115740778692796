import React, { useState } from 'react';
import './staff.css';
import { Link } from 'react-router-dom';
import { useServiceContext } from '../contextapi/Servicecontext';
import { useNavigate , useLocation} from 'react-router-dom';
import { useEffect } from 'react';

export default function Staff() {

  let location = useLocation();


  let selectslot = location.pathname.split("/");

  selectslot.pop();

  let slot =  selectslot.join("/");




  const { staffLists, selectedServices, setSelectedServices, translations } = useServiceContext();

  const handleSelect = (staffId, staffName) => {
    const isStaffSelected = selectedServices.some((item) => item.staffId === staffId);

    if (isStaffSelected) {
      // If staff is already selected, remove it
      const updatedServices = selectedServices.filter((item) => item.staffId !== staffId);
      setSelectedServices(updatedServices);
    } 

    else {
      // If staff is not selected, add it
      const updatedServices = [
        ...selectedServices.filter((item) => typeof item.staffId === 'undefined'), // Preserve other items
        { staffId, staffName },
      ];

      setSelectedServices(updatedServices);
    }
  };


  const staffIds = selectedServices.map(item => item.staffId);
  const uniqueStaffIds = [...new Set(staffIds)];
  const numberOfStaff = uniqueStaffIds.length;
  const isNextButtonEnabled = numberOfStaff > 1;

  const cardStyle = {
    margin: '5px',

  };

  const goBack = () => {
    history.goBack(); // Go back to the previous page in the history
  };

  const navigate = useNavigate();
  // useEffect(() => {
  //   const persistedServices = JSON.parse(localStorage.getItem('selectedServices')) || [];
  //   if (persistedServices.length > 0 && selectedServices.length === 0) {
  //     setSelectedServices(persistedServices);
  //   }
  
  //   if (!staffLists || staffLists.length === 0) {
  //     console.warn('staffLists is empty. Consider fetching it.');
  //   } else if (staffLists.length === 1) {
  //     const singleStaff = staffLists[0];
  //     const isAlreadySelected = selectedServices.some(
  //       (item) => item.staffId === singleStaff.staffId
  //     );
  //     if (!isAlreadySelected) {
  //       setSelectedServices([...selectedServices, {
  //         staffId: singleStaff.staffId,
  //         staffName: singleStaff.StaffName,
  //       }]);
  //     }
  //   }
  // }, [staffLists, selectedServices, setSelectedServices]);

  // useEffect(() => {
  //   localStorage.setItem('selectedServices', JSON.stringify(selectedServices));
  // }, [selectedServices]);
  
  useEffect(() => {
    if (staffLists?.length === 1) {
      // If only one staff member is available, preselect it
      const singleStaff = staffLists[0];
      setSelectedServices([   ... selectedServices ,{ staffId: singleStaff.staffId, staffName: singleStaff.StaffName }]);
    }
  }, [staffLists, setSelectedServices]);
  
  return (

    <>
      <div>
        <div className='text-center'>
          <span className='step float-start' onClick={() => navigate(-1)}   > <i className="fa-solid fa-arrow-left"></i>  </span> <span className='select'>{translations.select_staff}</span> <span className='step-right'>   {translations.step} 2/4  </span>
        </div>
        <div className="row scrollable-staff">

          {staffLists?.map((user) => (
            <div className="col-md-6" key={user.staffId}>

              <div className="card bg-light " style={cardStyle}>
                <div className="card-body text-center">
                  <div className='text-center'>
                    <img
                      src={user.profilePic ? `${user.imagePath}${user.profilePic}` : `${user.imagePath}${user.defaultProfilePic}`}
                      alt="User"
                      className="card-image staff-img"
                      width="100%"
                    />
                  </div>


                  <div className="staff-name mt-1">{user.StaffName}</div>
                  <button
                    className="select-button mt-2"
                    onClick={() => handleSelect(user.staffId, user.StaffName)}
                  >
                    {selectedServices.some((staff) => staff.staffId === user.staffId)

                      ? (translations.unselect)
                      : (translations.select)

                    }
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>  
      </div>
      <div>
        <div className="row">
          <div className="col-md-12 d-none d-xl-block d-md-block text-center">
            <Link to= {`${slot}/timeslot` }>
              <button className="next-button" disabled={!isNextButtonEnabled}   >{translations.next}</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
