    import React, { useEffect } from 'react';
    import './billingprice.css';
    import { useState } from 'react';
    import { useServiceContext } from '../contextapi/Servicecontext';


    export default function Billingprice() {

        const {
            selectedServices,
            removeService,
            setBookingdata,
            translations,
            toggleServiceIcon,
            showOffer,
            offerCode,
            setOfferCode,
            setOfferId
        

        } = useServiceContext();

        const [totalCost, setTotalCost] = useState(0);



        useEffect(() => {
            const hasStartingFrom = selectedServices.some(
                (service) => service.cost_type === "starting from"
            );
            const hasBetween = selectedServices.some(
                (service) => service.cost_type === "between"
            );
        
            const calculatedResult = selectedServices.reduce((acc, service) => {
                let serviceCost = 0;
                let minCost = 0;
                let maxCost = 0;
                let originalCost = 0;
        
                if (service.hasOwnProperty('Offer_id') && service.Offer_id !== '' && service.Offer_id !== undefined) {
                    if (service.cost_type === "between" && (!service.hasOwnProperty('discount_cost') || parseFloat(service.discount_cost) === 0)) {
                        // Special case: between with offer and discount_cost 0 or undefined -> total cost is 0
                        serviceCost = 0;
                        minCost = 0;
                        maxCost = 0;
                        originalCost = 0;
                    } else {
                        serviceCost = service.hasOwnProperty('discount_cost') ? (parseFloat(service.discount_cost) || 0) : 0;
                        minCost = serviceCost;
                        maxCost = serviceCost;
                        if (service.cost_type === "between") {
                            const costRange = service.cost.split('-');
                            minCost = parseFloat(costRange[0]);
                            maxCost = parseFloat(costRange[1]);
                            originalCost = hasStartingFrom ? minCost : maxCost;
                        } else if (service.cost_type === "starting from") {
                            originalCost = parseFloat(service.cost);
                        } else if (service.cost_type === "upto") {
                            originalCost = parseFloat(service.cost);
                        } else {
                            originalCost = parseFloat(service.cost);
                        }
                    }
                } else {
                    if (service.cost_type === "between") {
                        const costRange = service.cost.split('-');
                        minCost = parseFloat(costRange[0]);
                        maxCost = parseFloat(costRange[1]);
                        serviceCost = hasStartingFrom ? minCost : maxCost;
                        originalCost = serviceCost;
                    } else if (service.cost_type === "starting from") {
                        minCost = parseFloat(service.cost);
                        maxCost = parseFloat(service.cost);
                        serviceCost = minCost;
                        originalCost = serviceCost;
                    } else if (service.cost_type === "upto") {
                        minCost = 0;
                        maxCost = parseFloat(service.cost);
                        serviceCost = maxCost;
                        originalCost = serviceCost;
                    } else {
                        minCost = parseFloat(service.cost);
                        maxCost = parseFloat(service.cost);
                        serviceCost = minCost;
                        originalCost = serviceCost;
                    }
                }
        
                if (!isNaN(serviceCost)) {
                    acc.total += serviceCost;
                    acc.minTotal += minCost;
                    acc.maxTotal += maxCost;
                    acc.originalTotal += originalCost;
                }
                return acc;
            }, { total: 0, minTotal: 0, maxTotal: 0, originalTotal: 0 });
        
            let displayCost;
        
            // Check if total cost should be 0 due to between with offer and zero discount for all services
            const isZeroDueToOffer = selectedServices.every(service => 
                service.hasOwnProperty('Offer_id') && 
                service.Offer_id !== '' && 
                service.Offer_id !== undefined && 
                service.cost_type === "between" && 
                (!service.hasOwnProperty('discount_cost') || parseFloat(service.discount_cost) === 0)
            );
        
            const hasNonZeroOfferBetween = selectedServices.some(service => 
                service.hasOwnProperty('Offer_id') && 
                service.Offer_id !== '' && 
                service.Offer_id !== undefined && 
                service.cost_type === "between" && 
                service.hasOwnProperty('discount_cost') && 
                parseFloat(service.discount_cost) > 0
            );
        
            const hasRegularBetween = selectedServices.some(service => 
                service.cost_type === "between" && 
                (!service.hasOwnProperty('Offer_id') || service.Offer_id === '' || service.Offer_id === undefined)
            );
        
            if (isZeroDueToOffer) {
                displayCost = 0; // All services are between with offer and zero discount
            } else if (hasStartingFrom && (hasBetween || hasNonZeroOfferBetween)) {
                displayCost = calculatedResult.minTotal; // Use min total when starting from is present with any between
            } else if (hasBetween || hasNonZeroOfferBetween || hasRegularBetween) {
                // Only show range if there's a between service with non-zero cost contribution
                if (calculatedResult.minTotal === calculatedResult.maxTotal) {
                    displayCost = calculatedResult.total; // If min and max are equal, show single value
                } else {
                    displayCost = `${calculatedResult.minTotal}-${calculatedResult.maxTotal}`; // Otherwise show range
                }
            } else {
                displayCost = calculatedResult.total; // Default to single total
            }
        
            // Aggregate offer details (unchanged)
            const offerDetails = selectedServices
                .filter(service => service.hasOwnProperty('Offer_id') && service.Offer_id !== '' && service.Offer_id !== undefined)
                .map(service => ({
                    serviceIndex: service.serviceIndex,
                    Offer_id: service.Offer_id,
                    Offer_code: service.Offer_code || ''
                }));
        
            const offerCodesArray = offerDetails.map(detail => detail.Offer_code);
            const offerIdsArray = offerDetails.map(detail => detail.Offer_id);
        
            const offerCodes = offerCodesArray.length > 1 ? offerCodesArray.join(',') : (offerCodesArray[0] || '');
            const offerIds = offerIdsArray.length > 1 ? offerIdsArray.join(',') : (offerIdsArray[0] || '');
        
            setOfferCode(offerCodes);
            setOfferId(offerIds);
        
            setTotalCost(displayCost);
        
            setBookingdata((prevData) => ({
                ...prevData,
                totalCost: displayCost,
                originalTotal: calculatedResult.originalTotal,
            }));
        }, [selectedServices, setBookingdata, setOfferCode, setOfferId]);

// useEffect(() => {
//     console.log('selectedServices',selectedServices);
    
//     const hasStartingFrom = selectedServices.some(
//         (service) => service.cost_type === "starting from"
//     );
//     const hasBetween = selectedServices.some(
//         (service) => service.cost_type === "between"
//     );
    
//     const calculatedResult = selectedServices.reduce((acc, service) => {
//         let serviceCost = 0;
//         let minCost = 0;
//         let maxCost = 0;
//         let originalCost = 0;
        
//         if (service.hasOwnProperty('Offer_id') && service.Offer_id !== '' && service.Offer_id !== undefined) {
//             serviceCost = service.hasOwnProperty('discount_cost') ? (parseFloat(service.discount_cost) || 0) : 0;
//             minCost = serviceCost;
//             maxCost = serviceCost;
//             if (service.cost_type === "between") {
//                 const costRange = service.cost.split('-');
//                 minCost = parseFloat(costRange[0]);
//                 maxCost = parseFloat(costRange[1]);
//                 originalCost = hasStartingFrom ? minCost : maxCost;
//             } else if (service.cost_type === "starting from") {
//                 originalCost = parseFloat(service.cost);
//             } else if (service.cost_type === "upto") {
//                 originalCost = parseFloat(service.cost);
//             } else {
//                 originalCost = parseFloat(service.cost);
//             }
//         } else {
//             if (service.cost_type === "between") {
//                 const costRange = service.cost.split('-');
//                 minCost = parseFloat(costRange[0]);
//                 maxCost = parseFloat(costRange[1]);
//                 serviceCost = hasStartingFrom ? minCost : maxCost;
//                 originalCost = serviceCost;
//             } else if (service.cost_type === "starting from") {
//                 minCost = parseFloat(service.cost);
//                 maxCost = parseFloat(service.cost);
//                 serviceCost = minCost;
//                 originalCost = serviceCost;
//             } else if (service.cost_type === "upto") {
//                 minCost = 0;
//                 maxCost = parseFloat(service.cost);
//                 serviceCost = maxCost;
//                 originalCost = serviceCost;
//             } else {
//                 minCost = parseFloat(service.cost);
//                 maxCost = parseFloat(service.cost);
//                 serviceCost = minCost;
//                 originalCost = serviceCost;
//             }
//         }
        
//         if (!isNaN(serviceCost)) {
//             acc.total += serviceCost;
//             acc.minTotal += minCost;
//             acc.maxTotal += maxCost;
//             acc.originalTotal += originalCost;
//         }
//         return acc;
//     }, { total: 0, minTotal: 0, maxTotal: 0, originalTotal: 0 });
    
//     let displayCost;
    
//     if (hasStartingFrom && hasBetween) {
//         displayCost = calculatedResult.minTotal;
//     } else if (hasBetween) {
//         displayCost = `${calculatedResult.minTotal}-${calculatedResult.maxTotal}`;
//     } else {
//         displayCost = calculatedResult.total;
//     }
    
//     // Aggregate offer details
//     const offerDetails = selectedServices
//         .filter(service => service.hasOwnProperty('Offer_id') && service.Offer_id !== '' && service.Offer_id !== undefined)
//         .map(service => ({
//             serviceIndex: service.serviceIndex,
//             Offer_id: service.Offer_id,
//             Offer_code: service.Offer_code || ''
//         }));

//     // Extract offer codes and IDs
//     const offerCodesArray = offerDetails.map(detail => detail.Offer_code);
//     const offerIdsArray = offerDetails.map(detail => detail.Offer_id);

//     // Format for state: single value or comma-separated string
//     const offerCodes = offerCodesArray.length > 1 ? offerCodesArray.join(',') : (offerCodesArray[0] || '');
//     const offerIds = offerIdsArray.length > 1 ? offerIdsArray.join(',') : (offerIdsArray[0] || '');

//     // Set state with formatted values
//     setOfferCode(offerCodes);
//     setOfferId(offerIds);
    
//     setTotalCost(displayCost);
    
//     setBookingdata((prevData) => ({
//         ...prevData,
//         totalCost: displayCost,
//         originalTotal: calculatedResult.originalTotal,
//     }));
// }, [selectedServices, setBookingdata, setOfferCode, setOfferId]);
        



        // useEffect(() => {
        //     const hasStartingFrom = selectedServices.some(
        //         (service) => service.cost_type === "starting from"
        //     );
        //     const hasBetween = selectedServices.some(
        //         (service) => service.cost_type === "between"
        //     );
        //     const hasUpto = selectedServices.some(
        //         (service) => service.cost_type === "upto"
        //     );
        
        //     const calculatedResult = selectedServices.reduce((acc, service) => {
        //         let serviceCost = 0;
        //         let minCost = 0;
        //         let maxCost = 0;
        
        //         if (showOffer && service.discount_cost > 0) {
        //             serviceCost = parseFloat(service.discount_cost);
        //             minCost = serviceCost;
        //             maxCost = serviceCost;
        //         } else {
        //             if (service.cost_type === "between") {
        //                 const costRange = service.cost.split('-');
        //                 minCost = parseFloat(costRange[0]);
        //                 maxCost = parseFloat(costRange[1]);
        //                 serviceCost = hasStartingFrom ? minCost : maxCost;
        //             } else if (service.cost_type === "starting from") {
        //                 minCost = parseFloat(service.cost);
        //                 maxCost = parseFloat(service.cost);
        //                 serviceCost = minCost;
        //             } else if (service.cost_type === "upto") {
        //                 minCost = 0;
        //                 maxCost = parseFloat(service.cost);
        //                 serviceCost = maxCost;
        //             } else { // fix
        //                 minCost = parseFloat(service.cost);
        //                 maxCost = parseFloat(service.cost);
        //                 serviceCost = minCost;
        //             }
        //         }
        
        //         if (!isNaN(serviceCost)) {
        //             acc.total += serviceCost;
        //             acc.minTotal += minCost;
        //             acc.maxTotal += maxCost;
        //         }
        //         return acc;
        //     }, { total: 0, minTotal: 0, maxTotal: 0 });
        
        //     let displayCost;
        
        //     if (hasStartingFrom && hasBetween) {
        //         // When both starting from and between exist, show only min total
        //         displayCost = calculatedResult.minTotal;
        //     } else if (hasBetween || hasUpto) {
        //         // When either between or upto exists (without starting from overriding), show range
        //         displayCost = `${calculatedResult.minTotal}-${calculatedResult.maxTotal}`;
        //     } else {
        //         // Otherwise show single value
        //         displayCost = calculatedResult.total;
        //     }
        
        //     setTotalCost(displayCost);
        
        //     setBookingdata((prevData) => ({
        //         ...prevData,
        //         totalCost: displayCost
        //     }));
        // }, [selectedServices, setBookingdata, showOffer]);

        
        
        const handlremovefunction = ( categoryIndex, service ) => {

            toggleServiceIcon( categoryIndex ,service.serviceIndex)
            removeService(service)
        }
                
        const totalCount = selectedServices.filter(item => item.hasOwnProperty('serviceIndex')).length;

        // const calculateDiscount = () => {
        //     let discountAmount = 0;
        //     selectedServices.forEach(service => {
        //         if (showOffer && service.discount_cost > 0) {
        //             let originalCost = 0;

        //             if (service.cost_type === "between") {
        //                 // Assume using the upper limit of the range for discount calculation
        //                 originalCost = parseFloat(service.cost.split('-')[1]);
        //             } else {
        //                 originalCost = parseFloat(service.cost);
        //             }
        //             discountAmount += originalCost - parseFloat(service.discount_cost);
        //         }
        //     });
        //     return discountAmount;
        // };
        const calculateDiscount = () => {
            let discountAmount = 0;
            selectedServices.forEach(service => {
                if (service.hasOwnProperty('Offer_id') && service.Offer_id !== '' && service.Offer_id !== undefined) { // Apply discount only when Offer_id is non-empty and defined
                    let originalCost = 0;
                    if (service.cost_type === "between") {
                        const costRange = service.cost.split('-');
                        originalCost = parseFloat(costRange[1]); // Upper limit for consistency
                    } else {
                        originalCost = parseFloat(service.cost);
                    }
                    const discountedCost = service.hasOwnProperty('discount_cost') ? (parseFloat(service.discount_cost) || 0) : 0; // Default to 0 if discount_cost absent
                    if (!isNaN(originalCost) && !isNaN(discountedCost)) { // Ensure valid numbers
                        discountAmount += originalCost - discountedCost;
                    }
                }
            });
            return discountAmount >= 0 ? discountAmount : 0; // Ensure no negative discount
        };

    

        return (

            <>
                    <div className='scrollable-bill'>
                        { totalCount < 1 && <div className='selectedservices'> Selected services  will be shown here </div>}
                        
                    {selectedServices.map((service, index, categoryIndex, serviceIndex) => (

                        <div className='row flex-fill' key={serviceIndex}>
                            <div className='col-md-6 scrollable-content-alignment-1'>
                                <div className='row'>
                                    <text className='serviceprice-head'>{service.service_name}</text>
                                </div>
                                
                            </div>
                            <div className='col-md-3 scrollable-content-alignment-2'>

                                {service.service_name && service.cost && (

                                    <text className="mt-auto serviceprice-head float-end">
                                        <img
                                            src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/icons/Vector+(3).svg"
                                            alt="priceicon"
                                        />{' '}
                                        {service.cost}

                                    </text>
                                )}

                            </div>
                            
                            { service.service_name && service.cost && (<div className='remove-icon-billing col-md-3  ' style={{height : '24px' , width: '30px' , marginLeft:'30px' , cursor: 'pointer'}}  onClick={() => handlremovefunction(service.categoryIndex ,service)}
                                                        > <img
                                                                src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/icons/-.svg"
                                                                alt=""
                                                            />
                                                        </div>)}
                                                        <div className='col-md-12'>
                                    <text className='serviceprice-sub-head'>{service.category_name}</text><br />
                                    {/* {offerCode[serviceIndex] && (
        <text className='serviceprice-sub-head'>
            Offer Code: {offerCode[serviceIndex]}
        </text>
    )} */}
                                    </div>
                            {service.service_name && service.cost && (<div> <hr ></hr> </div>)}         
                        </div>

                    ))}


                </div>

                <hr></hr>
                <div className='d-flex align-items-center'>
                    <div className='col-md-4'>
                        <div className='discount'> <text>{translations.discount}</text> </div>
                        <div className='total-price'> <text>{translations.total}</text> </div>
                    </div>
                    <div className='col-md-8 text-end'>
                        <div className='discount'>  <img src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/icons/Vector+(3).svg" alt="" /><text>{calculateDiscount()}</text> </div>
                        <div classNa me='total-price'> <img src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/icons/Vector+(3).svg" alt="" /> <text>{totalCost}</text>  </div>
                    </div>
                </div>
            </>
        );
    }




