
import React from 'react';
import AppleLogin from 'react-apple-login';

const AppleLoginButton = ({setLoginModalVisibility}) => {
  const handleSuccess = (response) => {
    console.log('Apple Sign-In Success:', response);
    setLoginModalVisibility(false)
    // Handle the response from Apple (e.g., send token to your server)
  };

  const handleFailure = (error) => {
    console.error('Apple Sign-In Error:', error);
  };

  return (
    <div>
      <AppleLogin
        clientId="app.whizzq.stagingbiz"  // Your client ID from Apple Developer Portal
        redirectURI="https://stagingbiz.whizzq.app/l"  // Use a secure HTTPS URL
        scope="name email"
        responseType="code id_token" 
        responseMode="form_post"
        usePopup= {true}  // To open the sign-in in a popup window
        callback={handleSuccess}
        render={renderProps => (  //Custom Apple Sign in Button
          <button
            onClick={renderProps.onClick}
            style={{
                  backgroundColor: "white",
                  padding: 9,
                  border: "1px solid black",
                  borderRadius: '2px',
                  fontFamily: "none",
                  fontSize: "12px",
                  fontFamily: 'Poppins'
                }}
              >
                <i className="fa-brands fa-apple px-2 "></i>
                Continue with Apple
          </button>
            )}
      />
    </div>
  );
};

export default AppleLoginButton;
