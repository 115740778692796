// ServiceContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';


const ServiceContext = createContext();

export function useServiceContext() {

    return useContext(ServiceContext);
}

export function ServiceProvider({ children }) {


    const [selectedServices, setSelectedServices] = useState([]);
    const [serviceIcons, setServiceIcons] = useState([]);
    const [showstaff, setShowstaff] = useState('');
    const [staffLists, setStaffLists] = useState();
    const [bookingdata, setBookingdata] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [businessDetails, setBusinessDetails] = useState([])
    const [bookingbutton, setBookingButton] = useState([])
    const [abled, setAbled] = useState();
    const [translations, setTranslations] = useState({});
    const [selectedlocation, setSelectedlocation] = useState({})
    const [selectedDistance, setSelectedDistance] = useState(5);
    const [start_index, setStartIndex] = useState(0); // Initial start_index
    const [businesses, setBusinesses] = useState([]); // List of businesses
    const [searchbusiness, setSearchbusiness] = useState([]);
    const [buisnessid, setBusinessid] = useState('');
    const [businessname, setBusinessname] = useState('');
    const [businessnumber, setBusinessnumber] = useState('')
    const [nolocation, setNolocation] = useState('');
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
    const [totalservice, setTotalservice] = useState();
    const [businessid, setBusinessId] = useState();
    const [categoryIds, setCategoryIds] = useState();
    const [serviceIds, setServiceIds] = useState();
    const [activeTab, setActiveTab] = useState('service');
    const [showOffer, setShowoffer] = useState(false);
    const [queue, setQueue] = useState('');
    const [locationid, setLocationId] = useState('');
    const [offerId,setOfferId] = useState('')
    const [lastName, setLastName] = useState("");
    const [bookingTokens, setBookingToken] = useState();
    const [description,setDescription] = useState('')
    const [url,setUrl] = useState('')
    const [moduleType, setModuleType] = useState('');
    const [locationName,setLocationName] = useState('')
    const [srt_reffrence_no,setSrt_reffrence_no] = useState('')
    const [userId,setUserId] = useState('')
    const [userImage ,setUserImage] = useState()
    const [offervalidDate , setOffervalidDate] = useState();
    const [showCategory, setShowCategory] = useState();
    const [businessProfile, setBusinessProfile] = useState([]);
    const [businessOfferId , setBusinessOfferId] = useState();
    const [bookingSource , setBookingSource] = useState();
    const [partialPayment , setPartialPayment] = useState();
    const [razorpayDetails , setRazorpayDetails] = useState();
    const  [razorpayAmount, setRazorpayamount] = useState();
    const  [transactionLogId ,setTransactionLogId] = useState();
    const [offerCode,setOfferCode] = useState('')
    const [paymentMethode,setPaymentMethode] = useState()
        const [paymentMode, setPaymentMode] = useState(null); // State to store payment mode
    


    const toggleServiceIcon = (categoryIndex, serviceIndex, value) => {
        if (value == true) {

            setServiceIcons((prevIcons) => {
                const updatedIcons = Array.isArray(prevIcons) ? [...prevIcons] : [];
                updatedIcons[categoryIndex] = Array.isArray(updatedIcons[categoryIndex])
                    ? [...updatedIcons[categoryIndex]]
                    : [];
                const currentStatus = updatedIcons[categoryIndex][serviceIndex];
                if (currentStatus === true) {
                    return prevIcons; // Return the previous state unchanged
                }
                updatedIcons[categoryIndex][serviceIndex] = !currentStatus;
                return updatedIcons; // Return the updated array
            });
        } else {
            setServiceIcons((prevIcons) => {
                const updatedIcons = Array.isArray(prevIcons) ? [...prevIcons] : [];
                updatedIcons[categoryIndex] = Array.isArray(updatedIcons[categoryIndex])
                    ? [...updatedIcons[categoryIndex]]
                    : [];
                updatedIcons[categoryIndex][serviceIndex] = !updatedIcons[categoryIndex][serviceIndex];
                return updatedIcons; // Return the updated array
            });
        }
    };

    const addService = (service) => {
        setSelectedServices((prevServices) => {
            const updatedServices = [...prevServices, service];
            return updatedServices;
        });
    };
    const removeService = (service) => {
        const updatedServices = selectedServices?.filter((s) => s?.serviceIndex !== service?.serviceIndex);
        setSelectedServices(updatedServices);
    };
    const changeLanguage = (newLanguage) => {
        setLanguage(newLanguage);
        localStorage.setItem('language', newLanguage);
    };

    useEffect(() => {

        const loadTranslations = async () => {
            try {
                const langModule = await import(`../languages/${language}.json`);
                setTranslations(langModule.default);
            } catch (error) {
                console.error(`Error loading translations for ${language}:`, error);
            }
        };
        loadTranslations();
    }, [language]);

    useEffect(() => {
        
            if (selectedServices?.length > 0) {
                sessionStorage.setItem('selectedServices', JSON.stringify({ selectedServices }));
            }
    }, [selectedServices]);

    useEffect(() => {

        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            setLanguage(storedLanguage);
        }
    }, []);


    useEffect(() => {
        const timeout = setTimeout(() => {
          const storedData = sessionStorage.getItem("selectedServices")
          if (storedData) {

            try {
              const parsedData = JSON.parse(storedData);
                  if (parsedData && Array.isArray(parsedData.selectedServices)) {
                setSelectedServices(parsedData.selectedServices);
              } else if (Array.isArray(parsedData)) {
                setSelectedServices(parsedData);
              } else {
                console.error('Data format is incorrect or array not found:', parsedData);
                setSelectedServices([]); // Set to an empty array or handle as needed
              }
            } catch (error) {
              console.error('Error parsing JSON from sessionStorage:', error);
              setSelectedServices([]); // Set to an empty array or handle as needed
            }
          } else {
            console.error('No data found in sessionStorage.');
            setSelectedServices([]); // Set to an empty array or handle as needed
          }
        }, 1000); // 1 second in milliseconds
    
        return () => clearTimeout(timeout);
      }, [selectedlocation, setSelectedServices]);
 
      useEffect(() => {

        if(userImage != undefined)
        localStorage.setItem('userProfileImage', userImage ,{ expires: 30 })

      },[userImage])
 
    useEffect(() => {

        if (businessProfile?.length > 0) {
            sessionStorage.setItem('businessProfile', JSON.stringify(businessProfile));
        }
    }, [setBusinessProfile]);

    useEffect(() => {
        const storedBusinessProfile = sessionStorage.getItem('businessProfile');
        if (storedBusinessProfile) {
            setBusinessProfile(JSON.parse(storedBusinessProfile));
        }
    }, [setBusinessProfile]);

    return (

        <ServiceContext.Provider value={{
            selectedServices, setSelectedServices, addService,
            removeService, toggleServiceIcon, serviceIcons, showstaff, setShowstaff, staffLists,
            setStaffLists, bookingdata, setBookingdata, isLoggedIn, setIsLoggedIn,
            businessDetails, setBusinessDetails,
            bookingbutton, setBookingButton, abled
            , setAbled, language,
            changeLanguage,
            translations,
            selectedlocation, setSelectedlocation, selectedDistance, setSelectedDistance, start_index, setStartIndex,
            businesses, setBusinesses,
            searchbusiness, setSearchbusiness,
            buisnessid, setBusinessid,
            businessname, setBusinessname,
            businessnumber, setBusinessnumber, setServiceIcons, removeService,
            nolocation, setNolocation, totalservice, setTotalservice,
            businessid, setBusinessId,
            categoryIds, setCategoryIds,
            serviceIds, setServiceIds,
            activeTab, setActiveTab,
            showOffer, setShowoffer,
            queue, setQueue,
            locationid, setLocationId,
            lastName, setLastName,
            bookingTokens, setBookingToken,
            description,setDescription,
            url,setUrl,
            moduleType,
            setModuleType,
            locationName,setLocationName,
            srt_reffrence_no,setSrt_reffrence_no,
            userId,setUserId,
            userImage ,setUserImage,
            offervalidDate , setOffervalidDate,
            showCategory, setShowCategory,
            businessProfile, setBusinessProfile,
            businessOfferId , setBusinessOfferId,
            bookingSource , setBookingSource,
            partialPayment , setPartialPayment,
            razorpayDetails , setRazorpayDetails,
            razorpayAmount, setRazorpayamount,
            transactionLogId ,setTransactionLogId,
            paymentMode, setPaymentMode,
            offerId,setOfferId,
            offerCode,setOfferCode,
            paymentMethode,setPaymentMethode
        }}>
            {children}
        </ServiceContext.Provider>
    );
}
