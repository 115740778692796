import React, { useState } from 'react';
import AppleLogin from 'react-apple-login';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import apiservice from '../../apiServices';
import './usermodal.css'

const AppleLoginButton = ({ setLoginModalVisibility }) => {
  const [showModal, setShowModal] = useState(false); // Modal visibility from API response
  const [decodedToken, setDecodedToken] = useState();
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    mobile: ''
  });
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    mobile: ''
  });

  const appleclientId = process.env.REACT_APP_APPLE_CLIENT_ID;
  const appleredirectionUrl = process.env.REACT_APP_REDIRECTION_URL;

  // API call in success callback
  const handleLoginSuccess = async (response) => {
    try {
      const decodedToken = jwtDecode(response.authorization?.id_token);
      console.log('Decoded Apple Token:', decodedToken);
      setDecodedToken(decodedToken);

      // Make an API call to check whether to show the modal or not
      const api = process.env.REACT_APP_API_URL + 'LoginOrRegisterWithSocial';
      const apiResponse = await apiservice(api, 'POST', {
        sub: decodedToken.sub, // Apple's unique identifier for the user
        email: decodedToken.email,
        for_what: 'apple',
        user: 'login'
        


      });
       console.log('apiresponse',apiResponse)
      // Based on API response, control modal visibility
      if (apiResponse?.user_details_require === true || apiResponse?.user_details_require === "true") {
          console.log(apiResponse, 'apiresposne')
        setShowModal(true); // Show modal
       
      }
       else {

        const username = apiResponse.profile;
        const nameParts = username.split(' ');
        const firstName = nameParts[0];
        const lastName = nameParts.slice(1).join(' ');
        Cookies.set('firstname', firstName, { expires: 30 }); // Set expiry date as required
        Cookies.set('lastname', lastName, { expires: 30 });
        Cookies.set('bookingToken', apiResponse?.token, { expires: 30 });
        Cookies.set('mobile', apiResponse?.mobile, { expires: 30 });


        localStorage.setItem('userId', JSON.stringify(apiResponse?.userId));
        localStorage.setItem('mobileNumber', JSON.stringify(apiResponse.mobile));
       setLoginModalVisibility(false); // Close main login modal
      }


    } catch (error) {
      console.error('Error during Apple login:', error);
    }
  };

  const handleLoginFailure = (error) => {
    console.error('Apple Sign-In Error:', error);
  };

  // Validate form fields
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!userDetails.firstName.trim()) {
      newErrors.firstName = 'First name is required.';
      isValid = false;
    }

    if (!userDetails.lastName.trim()) {
      newErrors.lastName = 'Last name is required.';
      isValid = false;
    }

    if (userDetails.mobile.trim() && !/^\d{10}$/.test(userDetails.mobile)) {
      newErrors.mobile = 'Mobile number must be 10 digits.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  // Save user details to API
  const saveUserDetails = async (decodedToken, details) => {
    try {
      const { firstName, lastName, mobile } = details;

      const api = process.env.REACT_APP_API_URL + 'LoginOrRegisterWithSocial';
      const data = await apiservice(api, 'POST', {
        sub: decodedToken.sub,
        email: decodedToken.email,
        name: firstName + ' ' + lastName,
        mobile,
        for_what: 'apple',
        picture: "",
        user: 'register'
      });

      Cookies.set('bookingToken', data?.token, { expires: 30 });
      Cookies.set('firstname', firstName, { expires: 30 });
      Cookies.set('lastname', lastName, { expires: 30 });
      Cookies.set('mobile', mobile, { expires: 30 });

      localStorage.setItem('userId', JSON.stringify(data?.userId));
      localStorage.setItem('mobileNumber', JSON.stringify(mobile));

      setShowModal(false);
      setLoginModalVisibility(false); // Close main login modal
    } catch (error) {
      console.error('Error saving user details:', error);
    }
  };

  const handleSubmit = () => {
    if (!validateForm()) return; // Prevent API call if validation fails
    saveUserDetails(decodedToken, userDetails);
  };

  return (
    <>
      <div>
        <AppleLogin
          clientId={appleclientId}
          redirectURI={appleredirectionUrl}
          scope="name email"
          responseType="code id_token"
          responseMode="form_post"
          usePopup={true}
          callback={handleLoginSuccess}
          onFailure={handleLoginFailure}
          render={renderProps => (
            <button
              onClick={renderProps.onClick}
              style={{
                backgroundColor: "white",
                padding: 9,
                border: "1px solid black",
                borderRadius: '2px',
                fontFamily: "Poppins",
                fontSize: "12px",
              }}
            >
              <i className="fa-brands fa-apple px-2"></i>
              Continue with Apple
            </button>
          )}
        />
        {showModal && (
          <div className="modals">
            <div className="modal-content">
              <div>
                <label>First Name:</label>
                <input
                  type="text"
                  onChange={(e) => setUserDetails({ ...userDetails, firstName: e.target.value })}
                  placeholder='Enter your first name'
                  required
                />
                {errors.firstName && <p className="error">{errors.firstName}</p>}
              </div>
              <div>
                <label>Last Name:</label>
                <input
                  type="text"
                  onChange={(e) => setUserDetails({ ...userDetails, lastName: e.target.value })}
                  placeholder='Enter your last name'
                  required
                />
                {errors.lastName && <p className="error">{errors.lastName}</p>}
              </div>
              <div>
                <label>Mobile Number:</label>
                <input
                  type="text"
                  value={userDetails.mobile}
                  onChange={(e) => setUserDetails({ ...userDetails, mobile: e.target.value })}
                  placeholder='Enter your Mobile No.'
                />
                {errors.mobile && <p className="error">{errors.mobile}</p>}
              </div>
              <div>
                <button
                  onClick={() => {
                    setShowModal(false);
                    setLoginModalVisibility(false);
                  }}
                  className='cancel'
                >
                  Cancel
                </button>
                <button onClick={handleSubmit} className='submit'>Submit</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AppleLoginButton;
