import React, { useState, useEffect } from 'react';
import './business.css';
import apiservice from '../../apiServices';

export default function Banner() {
    const [banners, setBanners] = useState([]);

    const fetchBanners = async () => {
        const api = process.env.REACT_APP_API_URL + 'banner';
        try {
            const data = await apiservice(api, 'GET');
            setBanners(data.bannerList); // Directly set the fetched data without filtering
        } catch (error) {
            console.error('Error fetching banners:', error);
        }
    };

    useEffect(() => {
        fetchBanners();
    }, []);

    const hasInvalidBanners = banners.some(banner => banner.banner_url === null || banner.banner_url === '');

    return (
        <>
            {!hasInvalidBanners && banners.length > 0 && (
                <div className='col-md-12'>
                    <div className="bd-example">
                        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
                            <div className="carousel-indicators">
                                {banners.map((banner, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        data-bs-target="#carouselExampleCaptions"
                                        data-bs-slide-to={index}
                                        className={index === 0 ? "active" : ""}
                                        aria-current={index === 0 ? "true" : "false"}
                                        aria-label={`Slide ${index + 1}`}
                                    ></button>
                                ))}
                            </div>

                            <div className="carousel-inner">
                                {banners.map((banner, index) => (
                                    <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                        <img src={banner.banner_url} className="d-block w-100" alt={`Banner ${index + 1}`} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
