import React, { useState } from 'react';
import { useRazorpay } from "react-razorpay";
import './payment.css'
import { useServiceContext } from '../contextapi/Servicecontext';
import { FaCreditCard } from "react-icons/fa"


const PaymentComponent = ({ onSuccess }) => {
  
  
  const { error, isLoading, Razorpay } = useRazorpay();
  
  const razorpaykey = process.env.REACT_APP_RAZORPAY_KEY_ID;
  const [orderData, setOrderData] = useState(null);
  
  const{selectedServices , bookingdata , partialPayment , setRazorpayDetails ,setRazorpayamount ,setTransactionLogId,paymentMethode,setPaymentMethode} = useServiceContext();
   
   const businesslocationId = sessionStorage?.getItem('businesslocationId')
   const userId =  localStorage.getItem('userId')





   function calculatePercentageAmount(percentage, value) {
    if (!Number.isFinite(percentage) || !Number.isFinite(value)) {
      throw new TypeError('Both percentage and value must be finite numbers');
    }
    return (percentage / 100) * value;
  }

  let partialAmount = 0;

  if (Number.isFinite(partialPayment) && bookingdata && Number.isFinite(bookingdata.totalCost)) {
    partialAmount = calculatePercentageAmount(partialPayment, bookingdata.totalCost);
  } else {
    console.warn('Invalid partialPayment or bookingdata.totalCost');
    partialAmount = bookingdata.totalCost
  }  

   setRazorpayamount(partialAmount)
  const remainingAmount = bookingdata.totalCost - partialAmount

  const handlePayment = async () => {
    try {
      const isPartial = Number.isFinite(partialPayment) && partialPayment > 0;
      // Send a request to the server to create or check the order using fetch
      const response = await fetch('https://stagingapi.whizzq.app/api/create_order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          listing_id: businesslocationId,
          cust_id: userId,
          amount: bookingdata.totalCost,
          paid_amount: partialAmount,
          discount_amount: "",
          remaining_amount: remainingAmount,
          // total_amount: bookingdata.totalCost,
          total_amount:partialAmount,
          partial:isPartial
        }),
      });

      const data = await response.json();

      if (data.status) {
        const { razorPayData, message,transactionLogId } = data;
        setTransactionLogId(data.transactionLogId)
        

          const options = {
            key: razorpaykey,
            amount: razorPayData.total_amount * 100, // Convert to paise
            currency: "INR",
            name: "SchedMad Pvt. Ltd",
            description: "Test Transaction",
            order_id: razorPayData.razorPayOrderId, // Use the order ID returned by the server
            handler: (paymentResponse) => {
              setRazorpayDetails(paymentResponse)
              
              // You can send paymentResponse to your server for further verification
              if (onSuccess && typeof onSuccess === "function") {
                // onSuccess(paymentResponse);
                onSuccess({ ...paymentResponse, transactionLogId });
                
              }
            },
            prefill: {
              name: "John Doe",
              email: "john.doe@example.com",
              contact: "9999999999",
            },
            theme: {
              color: "#1D3557",
            },
          };
          const razorpayInstance = new Razorpay(options);
          razorpayInstance.open();
        
    }
    } catch (err) {
      console.error("Error creating order:", err);
      // alert("Error creating order, please try again.");
    }
  };

  return (
    <div className='payment-btn'> 

      <button onClick={() => {
        handlePayment();
        setPaymentMethode('online')
        }}  disabled={!bookingdata?.totalCost}>
      <FaCreditCard size={18} style={{ marginRight: "6px" }} /> 

       <span>Pay Online</span> </button>
    </div>
  );
};

export default PaymentComponent;
