import React, { useEffect } from "react";
import "./Details.css";
import QRCode from "qrcode.react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useServiceContext } from "../contextapi/Servicecontext";

const Details = () => {
    const { translations } = useServiceContext();
    const location = useLocation();
    const navigate = useNavigate();
    const { appointment, isPast } = location.state || {};

    const businesslogopath = appointment.logo_path;

    // Calculate total duration and total amount
    const totalDuration = appointment?.services?.reduce((total, service) => {
        const duration = parseFloat(service.durration);
        return total + (isNaN(duration) ? 0 : duration);
    }, 0);

    const totalAmount = appointment?.services?.reduce((total, service) => {
        const cost = parseFloat(service.cost);
        return total + (isNaN(cost) ? 0 : cost);
    }, 0);

    useEffect(() => {
        const handlePopState = (event) => {
            navigate('/l/appointments');
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate]);

    if (!appointment) {
        return <div>No appointment data found.</div>;
    }


    return (
        <>
            <div className="info-container">
                <div className="ref-token">
                    <Link to='/l/appointments'>
                        <button className="back"><i className="fa-solid fa-arrow-left"></i></button>
                    </Link>

                    <div className="ref-content">
                        <b><span>Ref/No: {appointment.refNo}</span></b>
                        {appointment.tokenNo && (
                            <b><span style={{ color: 'green', fontWeight: 'bold' }}>T/No: {appointment.tokenNo}</span></b>
                        )}
                    </div>
                    <div className="bussiness-infoo">
                        <div className="logo-info">

                            <img
                                src={
                                    appointment.BussinessLogo && appointment.BussinessLogo.trim() !== ''
                                        ? `${businesslogopath}${appointment.BussinessLogo}`
                                        : 'https://whizzdatalocal.s3-us-west-2.amazonaws.com/business_images/business_default.png'
                                }
                                alt="Business Logo"
                            />
                        </div>
                        <div className="name-info">
                            <b><span>{appointment.BussinessName}</span></b>
                            <div className="catagory-info">
                                {appointment?.services?.map(service => (
                                    <div className="catagory-name" key={service.s_id}>{service.s_name}</div>
                                ))}
                            </div>
                        </div>
                        {!isPast && (
                            <Link to={`/l/track/${appointment.refNo}`}>
                                <button className="buttons-right">
                                    Track
                                </button>
                            </Link>
                        )}
                    </div>

                    <div className="info-appoinment">
                        <span className="label">{translations.Date}:</span>
                        <span className="value">{appointment.date}</span>
                        <span className="label">{translations["Scheduled Time"]}:</span>
                        <span className="value">{appointment.start}</span>
                        <span className="label">{translations["Total Duration"]}:</span>
                        <span className="value">{totalDuration} min</span>
                        {appointment.Offer_code && (
                            <>
                            <span className="label">Offer Code</span>
                            <span className="value">{appointment.Offer_code}</span>
                            </>
                        )}

                        <span className="label">{translations["Total Amount"]}:</span>
                        <span className="value">{appointment.TotalAmount}</span>

                        <span className="label">{translations["Payment Mode"]}:</span>
                        <span className="value">{appointment.payment_type}</span>

                        {(appointment.rating !== undefined && appointment.rating !== null) || (appointment.comment !== undefined && appointment.comment !== null) ? (
                            <>
                                {appointment.rating !== null && (
                                    <>
                                        <span className="label">{translations.Rating}</span>
                                        <span className="value">
                                            {[...Array(5)].map((_, index) => (
                                                <span key={index} className={index < appointment.rating ? 'stars filled' : 'stars'}>
                                                    ★
                                                </span>
                                            ))}
                                        </span>
                                    </>
                                )}
                                {appointment.comment !== null && (
                                    <>
                                        <span className="label">{translations.Comment}</span>
                                        <span className="value">{appointment.comment}</span>
                                    </>
                                )}
                            </>
                        ) : null}


                    </div>
                    <div className="qr-info">
                        <span className="qr-text-info">{translations["Your Booking QR code is"]}</span>
                        <div className="qr-code-info">
                            <QRCode value={appointment.refNo} size={128} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="info-container">
                <div className="personal-text">
                    <span>Personal information</span>
                </div>
                <div className="ref-token">
                    <div className="info-appoinment">
                        <span className="label">{translations.Name}:</span>
                        <span className="value">{`${appointment.FirstName} ${appointment.LastName}`}</span>
                        <span className="label">{translations["Contact Number"]}:</span>
                        <span className="value">{appointment.mobile}</span>
                        <span className="label">{translations.Email}:</span>
                        <span className="value">{appointment.email}</span>
                        <span className="label">{translations.Note}:</span>
                        <span className="value">{appointment.Note}</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Details;
